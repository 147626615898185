import React, {useEffect, useState} from "react";
import {View, Text, StyleSheet, TouchableWithoutFeedback, FlatList} from "react-native";
import {Button, VStack, Image, HStack, Box} from "native-base";
import { useNavigation } from "@react-navigation/native";
import DashedLine from "react-native-dashed-line";
import {Cart, mapToItems, tagOptions} from "./lib/model";
import {useRecoilState, useRecoilValue} from "recoil";
import {cartState} from "./state/cartState";
import apps from "./lib/apps";
import {orderState} from "./state/orderState";
import {useMutation} from "@tanstack/react-query";
import {Order} from "./lib/order_model";
import * as remote from "./lib/remote";

function Title() {
    const navigation = useNavigation<any>();
    const [cart, setCart] = useRecoilState(cartState);

    return (
        <HStack style={styles.titleContainer}>
            <TouchableWithoutFeedback onPress={() => {
                setCart(new Cart(apps.getHappyhour().saleMode, apps.currentShop!!))
                navigation.popToTop();
            }}>
                <Image source={require("../assets/back.png")} style={styles.titleBack} alt="back" />
            </TouchableWithoutFeedback>
            <Text style={styles.title}>Receipt</Text>
        </HStack>
    );
}

function Thanks({ordNo} : {ordNo: number}) {
    const [yourOrder, setYourOrder] = useRecoilState(orderState);

    return (
        <VStack style={{ backgroundColor: "#f7f5f2", borderTopColor: "#dbd3c6", borderTopWidth: 1 }}>
            <Box style={{ height: 176 }}>
                <Image source={require("../assets/thanks.png")} alt="thanks" w={360} h={158} />
            </Box>
            <Box style={{ height: 120, alignItems: "center" }}>
                <Text style={{
                    paddingTop: 10, fontSize: 30, color: "#db146d", fontWeight: "900", letterSpacing: -0.75,
                    width: 190, height: 75, lineHeight: 30, textAlign: "center",
                }}>THANKS FOR ORDERING</Text>
                <Text style={{
                    color: "#a0927d", fontSize: 16, fontWeight: "900", letterSpacing: -0.4, marginBottom: 30,
                }}>
                    {yourOrder.getOrderInfoLong()}
                </Text>
            </Box>
            {(apps.isTakeaway() && ordNo > 0) && <Box style={{height: 90, alignItems: "center", justifyContent: "flex-start"}}>
                    <Text style={{color: "#a0927d", fontSize: 14, fontWeight: "900", letterSpacing: -0.4, marginBottom: 3}}>Order Number</Text>
                    <Text style={{fontSize: 60, color: "#db146d", fontWeight: "900", letterSpacing: -0.75,}}>
                        {ordNo}
                    </Text>
            </Box>}
            {(apps.currentShop != null && apps.currentShop.shopid == "sushikiyocpr" && apps.currentShop.tblNo.startsWith("Q")) &&
                <Box style={{height: 80, alignItems: "center", justifyContent: "flex-start", padding: 20}}>
                    <Text style={{color: "#db146d", fontSize: 23, fontWeight: "900", letterSpacing: -0.4, marginBottom: 3, textAlign: "center"}}>
                        Please pay at the counter first after placing your order
                    </Text>
                </Box>
            }
            <Image source={require("../assets/paperbottom.png")} alt="pb" h="18px" w="100%" />
        </VStack>

    );
}

function Total() {

    const cart = useRecoilValue(cartState);
    // State variables for subtotal, fee, and total
    const [subtotal, setSubtotal] = useState('$' + cart.total().toFixed(1).toString());  // Default is from cart
    const [fee, setFee] = useState('$0.0');  // Default to 0.0
    const [total, setTotal] = useState('$' + cart.total().toFixed(1).toString());  // Default is from cart


    const receipt = useMutation((orderId: number) => remote.findReceiptByOrderId(orderId),
        {
            onSuccess: (res) => {
                //console.log("onSuccess: receipt =>  ", JSON.stringify(res, null, 2));
                if (res && Object.keys(res).length > 0) {
                    setSubtotal(res.originalAmount ? res.originalAmount : '$' + cart.total().toFixed(2).toString());  // Set originalAmount if available, or fallback to cart total
                    setFee(res.surchargeAmount ? res.surchargeAmount : '$0.0');  // Set surchargeAmount if available, or default to '0.0'
                    setTotal(res.total ? res.total : '$' + cart.total().toFixed(2).toString());  // Set total if available, or fallback to cart total
                }
            },
            onError: (err) => {
                // @ts-ignore
                // alert(`err: ${err}`);
            },
            onMutate: (orderId) => {
                //console.log("getting: receipt =>  ", JSON.stringify(orderId, null, 2));
            },
        });

    useEffect(() => {
        // Only trigger receipt.mutate when the component mounts or when apps.ordId changes
        if (apps.ordId) {
            receipt.mutate(apps.ordId);
        }
    }, [apps.ordId]);  // Depend on apps.ordId so it runs only when this value changes



    return (
        <VStack style={styles.totalContainer}>
            <HStack style={styles.totalRow}>
                <Text style={styles.totalLabel}>SubTotal</Text>
                <Text style={styles.totalPrice}>{subtotal}</Text>
            </HStack>
            <HStack style={styles.totalRow}>
                <Text style={styles.totalLabel}>Processing Fee</Text>
                <Text style={styles.totalPrice}>{fee}</Text>
            </HStack>
            <HStack style={styles.totalRowLast}>
                <Text style={styles.totalLabel}>Total</Text>
                <Text style={styles.totalPrice}>{total}</Text>
            </HStack>
        </VStack>
    );
}

function ItemsAndBottom() {
    const [cart, setCart] = useRecoilState(cartState);
    const navigation = useNavigation<any>();

    return (
        <VStack style={{ ...styles.container, flex: 1 }}>
            <Box style={{
                height: 40,
                justifyContent: "center",
                borderBottomColor: "#434343",
                borderBottomWidth: 1,
            }}>
                <Text style={{
                    fontSize: 16,
                    fontWeight: "900",
                    color: "#434343",
                }}>Your items</Text>
            </Box>
            <FlatList data={mapToItems(cart.items)} scrollEnabled={true} showsVerticalScrollIndicator={false}
                      ListFooterComponent={
                          <>
                              <Total />
                          </>
                      }

                      renderItem={({ item, index }) => {
                          const isLast = index === cart.items.length - 1;

                          return (
                              <VStack style={{ width: "100%", backgroundColor: "white" }}>
                                  <HStack style={styles.itemList}>
                                      <VStack style={styles.itemLeft}>
                                          <Text style={styles.itemName}>{item.name}</Text>
                                          <Text style={styles.itemOptions}>{item.optionsDescr()}</Text>
                                      </VStack>
                                      <VStack style={styles.itemRight}>
                                          <Text style={styles.itemPrice}>${item.total.toFixed(1)}</Text>
                                          <Box style={styles.itemQtyBox}>
                                              <Text style={styles.itemQty}>x{item.qty}</Text>
                                          </Box>
                                      </VStack>
                                  </HStack>
                                  {!isLast ?
                                      <DashedLine dashLength={6} dashGap={6} dashColor="#434343" style={{ height: 1 }} /> : null
                                  }
                              </VStack>
                          );
                      }
                      } />
        </VStack>
    );
}

function Ordered({navigation}) {
    const ordNo = apps.getOrdNoAndClear();

    return (
        <VStack style={{ flex: 1, backgroundColor: "white" }}>
            <Title />
            <Thanks ordNo={ordNo}/>
            <ItemsAndBottom />
        </VStack>
    )
}

const styles = StyleSheet.create({
    container: {
        marginTop: 15,
        marginLeft: 20,
        marginRight: 20,
    },
    titleContainer: {
        height: 49,
        backgroundColor: "white",
        justifyContent: "flex-start",
        alignItems: "center",
        marginLeft: 20,
    }, title: {
        fontSize: 22,
        fontWeight: "900",
        color: "#434343",
        letterSpacing: -0.55,
        lineHeight: 35,
    }, titleBack: {
        height: 26, width: 26,
        marginTop: 5, marginRight: 10,
    },
    itemList: {
        width: "100%", backgroundColor: "white", height: 59,
        // borderBottomWidth: 1, borderBottomColor: "#434343",
        paddingTop: 6,
    }, itemLeft: {
        flex: 1, justifyContent: "center",
    }, itemName: {
        fontSize: 14,
        fontWeight: "bold",
        letterSpacing: -0.3,
        color: "#434343",
    }, itemOptions: {
        fontSize: 12,
        letterSpacing: -0.3,
        color: "#999",
    }, itemRight: {
        justifyContent: "flex-start", alignItems: "flex-end",
    }, itemPrice: {
        fontSize: 16,
        color: "#434343",
        letterSpacing: -0.3,
    }, itemQtyBox: {
        marginTop: 3,
        borderRadius: 9,
        borderWidth: 0,
        backgroundColor: "#999999",
        paddingHorizontal: 5,
        paddingVertical: 0,
        width: 25,
        height: 20,
        alignItems: "center",
        justifyContent: "center",
    }, itemQty: {
        marginTop: -2,
        fontSize: 12,
        fontWeight: "bold",
        color: "white",
    },
    totalContainer: {
        backgroundColor: "white",
    }, totalRow: {
        height: 39, width: "100%",
        borderTopWidth: 1, borderTopColor: "#434343", alignItems: "center",
    }, totalLabel: {
        flex: 1, justifyContent: "center",
        fontSize: 16, fontWeight: "900", color: "#434343", letterSpacing: -0.4,
    }, totalPrice: {
        justifyContent: "center", alignItems: "flex-end", fontSize: 16, letterSpacing: -0.3,
    }, totalRowLast: {
        height: 39, width: "100%",
        borderTopWidth: 1, borderTopColor: "#434343", alignItems: "center",
        borderBottomWidth: 1, borderBottomColor: "#434343",
    },

});

export default Ordered;
